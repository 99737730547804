<template>
    <div class="my-6">
        <h3 class="font-bold">Criar um agendamento de vidada de Lote</h3>
        <span class="text-sm text-gray-500">Preencha as informações abaixo e clique no botão <b>criar</b> para
            salvar as alterações.
        </span>
    </div>
    <div class="flex flex-row items-center">
        <div class="w-full p-2">
            <Input type="datalist" id="evento" v-model="eventoId" label="Evento" placeholder="Selecione um evento"
                :data="eventos" @keyupSearch="getEvent" />
        </div>
        <div class="mt-3 justify-content-center">
            <Button color="primary" :text="`Buscar`" @click="getProdutos" />
        </div>
    </div>
    <hr class="my-9" />
    <div class="card shadow-sm border p-8 bg-white">

        <div class="flex lg:flex-row flex-col items-center">
            <div class="w-full p-2">
                <Input type="default" id="detalhes" label="Detalhes" placeholder="Detalhes" v-model="detalhes" />
            </div>
            <div class="w-full p-2">
                <Input v-model="dia" type="date" id="data" label="Data" :minDate="today" />
            </div>
            <div class="w-full p-2">
                <Input v-model="hora" id="hora" type="customTime" label="Hora da troca de lote"
                    placeholder="Hora da troca de lote" />
            </div>
            <div class="w-full p-2">
                <Input type="select" id="status" v-model="tipoAgendamentSelect" label="Tipo de Agendamento"
                    placeholder="Selecione um Tipo de Agendamento" :data="tipoAgendamentoOption" />
            </div>

        </div>
        <div class="flex lg:flex-row flex-col items-center">
            <div class="w-full p-2" v-if="tipoAgendamentSelect != 2">
                <Input type="datalist" id="produtoDesativado" v-model="desativarId"
                    :label="`${tipoAgendamentSelect == 4 ? ' Selecione o produto que dava ficar sem estoque' : 'Desativar Produto'} `"
                    placeholder="Selecione o produto" :data="produtos" />
            </div>

            <div class="w-full p-2" v-if="tipoAgendamentSelect == 1 || tipoAgendamentSelect == 2">
                <Input type="datalist" id="produtoAtivado" v-model="ativarId" label="Ativar Produto"
                    placeholder="Selecione o produto" :data="produtos" />
            </div>
        </div>

        <div class="flex justify-end my-2">
            <Button @click="addTrocas()" color="primary" text="Adicionar" />
        </div>
    </div>

    <hr>
    <div class="card shadow-sm border p-8 bg-white">
        <div v-for="(item, index) in trocas" :key="index" class="flex justify-evenly items-center my-5 w-full">

            <div :class="`flex justify-evenly items-center ${tipoAgendamentSelect == 1 ? 'gap-5' : ' w-2/3'
                }`">
                <ProdutoLote :produtoDetalhes="item.desativa.detalhes" :produtoValor="item.desativa.valor"
                    :produtoSKU="item.desativa.cod_produto" :produtoQuantidade="item.desativa.qnt_por_produto"
                    v-if="tipoAgendamentSelect != 2" />

                <fa :icon="`fa-solid fa-circle-arrow-right`" size="xl" v-if="tipoAgendamentSelect == 1" />

                <ProdutoLote :produtoDetalhes="item.ativa.detalhes" :produtoValor="item.ativa.valor"
                    :produtoSKU="item.ativa.cod_produto" :produtoQuantidade="item.ativa.qnt_por_produto"
                    v-if="tipoAgendamentSelect == 1 || tipoAgendamentSelect == 2" />

            </div>

            <fa :icon="`fa-solid fa-trash`" size="xl" @click="removeLote(index)" class="flex-2" />
        </div>
    </div>

    <div class="flex justify-end mt-5">
        <Button @click="create()" color="primary" text="Criar Agendamento" />
    </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import ProdutoLote from '../../components/Elements/ProdutoLote.vue'
import { inject, onMounted, ref, watch } from "vue";
import Button from "../../components/Elements/Button.vue";
import { toDate } from "../../services/formater"
import { GET, POST } from "../../services/api";
import { useRouter } from "vue-router";
import { convertToUTC,toDateUS } from "@/services/formater";
import moment from "moment";
export default {
    name: "CriarAgendamento",
    components: {
        Input,
        Button,
        ProdutoLote
    },
    setup() {
        const eventos = ref([])
        const alert = inject("alert");
        const loader = inject("loading");
        const produtos = ref([]);
        const Resprodutos = ref([]);
        const eventoId = ref();
        const evento = ref('');
        const eventosOriginal = ref([]);
        const desativarId = ref("");
        const ativarId = ref("");
        const detalhes = ref("")
        const dia = ref("")
        const router = useRouter();
        const hora = ref("")


        const today =moment(new Date()).format("YYYY-MM-DD");

        const tipoAgendamentoOption = ref([
            {
                label: 'Alterar Lote',
                value: '1'
            }, {
                label: 'Ativar Produto',
                value: '2'
            }, {
                label: 'Desativar Produto',
                value: '3'
            },
            {
                label: 'Parar Vendas',
                value: '4'
            }
        ])
        const tipoAgendamentSelect = ref('1')

        const trocas = ref([])



        const addTrocas = () => {
            try {
                if (tipoAgendamentSelect.value == 1) {
                    const [PDesativado] = produtos.value.filter(
                        (prod) => prod.label == desativarId.value
                    );

                    const [PAtivar] = produtos.value.filter(
                        (prod) => prod.label == ativarId.value
                    );

                    const [ProdutoDesativado] = Resprodutos.value.filter((prod) => prod.id === PDesativado.value)
                    const [ProdutoAtivado] = Resprodutos.value.filter((prod) => prod.id === PAtivar.value)

                    trocas.value.push({
                        desativa: ProdutoDesativado,
                        ativa: ProdutoAtivado
                    })

                } else if (tipoAgendamentSelect.value == 2) {
                    const [PAtivar] = produtos.value.filter(
                        (prod) => prod.label == ativarId.value
                    );

                    const [ProdutoDesativado] = Resprodutos.value.filter((prod) => prod.id === PAtivar.value)
                    const [ProdutoAtivado] = Resprodutos.value.filter((prod) => prod.id === PAtivar.value)

                    trocas.value.push({
                        desativa: ProdutoDesativado,
                        ativa: ProdutoAtivado
                    })

                } else if (tipoAgendamentSelect.value == 3 || tipoAgendamentSelect.value == 4) {

                    const [PDesativado] = produtos.value.filter(
                        (prod) => prod.label == desativarId.value
                    );

                    const [ProdutoDesativado] = Resprodutos.value.filter((prod) => prod.id === PDesativado.value)
                    const [ProdutoAtivado] = Resprodutos.value.filter((prod) => prod.id === PDesativado.value)

                    trocas.value.push({
                        desativa: ProdutoDesativado,
                        ativa: ProdutoAtivado
                    })
                }



            } catch (error) {
                alert.open("Error", "um erro ocorreu, tente novamente", "danger")
            }
        }


        const create = async () => {
            const usuarioConta = JSON.parse(localStorage.getItem('info_santo_cartao_adm'))

            const correctDate = dia.value
                .replace("-", "/")
                .replace("-", "/")
                .split("/")
                .reverse()
                .join("/");


            const body = {
                identificacao: detalhes.value,
                data: convertToUTC(correctDate, hora.value, usuarioConta.timeZone),
                "tipo_agendamento": tipoAgendamentSelect.value,
                produtos: []

            }
            body.produtos = trocas.value.map((prod) => {
                return {
                    produtoAtivado: prod.ativa.id,
                    produtoDesativado: prod.desativa.id
                }
            }

            )

            if (!body.data || !body.identificacao || body.produtos.length === 0 || !dia.value || !hora.value) {
                alert.open("Atenção!", 'Campos faltando, confira novamente', "warning");
            } else {
                try {
                    loader.open();
                    await POST('/agendamento_lote', body)
                    loader.close()

                    alert.open("Sucesso!", `Agendamento criado com sucesso!`, "success");
                    router.push(`/lotes`);


                } catch (error) {
                    loader.close()
                    alert.open("Error", "Não foi possivel criar o Agendamento", "danger")
                }
            }



        }


        onMounted(async () => {
            try {
                loader.open();
                const response = await GET("event");
                eventosOriginal.value = response
                response.map(evento => eventos.value.push({ label: evento.nome + " - " + toDate(evento.data), value: evento.id }));

                loader.close()
            } catch (error) {
                loader.close()
                alert.open("Error", "Não foi possivel recuperar os eventos", "danger")
            }
        })

        const getProdutos = async () => {

            trocas.value = []
            produtos.value = []
            ativarId.value = ""
            desativarId.value = ""
            evento.value = ""

            loader.open();

            try {
                const [eventoSelecionado] = eventos.value.filter(
                    (evento) => evento.label == eventoId.value
                );

                const [eventoSelecionadoOriginal] = eventosOriginal.value.filter(
                    (evento) => evento.id == eventoSelecionado.value
                );

                eventoId.value = eventoSelecionado.value;


                Resprodutos.value = await GET(`produto/${eventoId.value}/evento`);

                evento.value = eventoSelecionadoOriginal.id


                Resprodutos.value.map(prod => {
                    produtos.value.push({ label: prod.cod_produto, value: prod.id })
                })

                if (produtos.value.length <= 0)
                    alert.open("Atenção!", `Não há produtos relacionados a esse evento!`, "warning");

                eventoId.value = eventoSelecionado.label;


            } catch (e) {

                alert.open("Erro", "Um erro ocorreu, tente novamente", "danger");

            }
            loader.close();
        }

        const removeLote = (index) => {


            trocas.value.splice(index, 1)


        }


        watch(
            () => tipoAgendamentSelect.value,
            () => {
                trocas.value = []
                ativarId.value = ""
                desativarId.value = ""
            }
        )

        return {
            eventos,
            getProdutos,
            eventoId,
            produtos,
            detalhes,
            dia,
            hora,
            desativarId,
            ativarId,
            trocas,
            addTrocas,
            removeLote,
            create,
            tipoAgendamentoOption,
            tipoAgendamentSelect,
            today,toDateUS

        }
    }
}


</script>

<style></style>